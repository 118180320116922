import { environment } from 'src/environments/environment.development';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export enum AscDesc{ 
  LATEST = 1,
  OLDEST = 0
}

export interface IOrder{ 
  customerName: string;
  customerPhoneNumber: string;
  carBrand: string;
  carModel: string;
  carPlateNumber: string;
  branchId?: number;
  notes?: string
}

export interface IQuery { 
    pageSize?: number;
    pageNumber?: number;
    statusId?: number;
    From?: string;
    To?: string;
    BranchId? : number,
    ResultOrder?: AscDesc;
    orderId?: string;
    branchId?: number,
    SearchByText?: string,
    SearchBy?:number,
    SpotId?: number | null ,
    note?:string|null
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private httpClient: HttpClient,
  ) { }


  listOrders({pageNumber, pageSize, statusId, From, To, BranchId, ResultOrder,SearchByText,SearchBy,SpotId}: IQuery) {
    const baseUrl = `${environment.apiUrl}Order`;
    const params = {} as IQuery;
    if (pageNumber) params['pageNumber'] = pageNumber;
    if (pageSize) params['pageSize'] = pageSize;
    if (statusId) params['statusId'] = statusId;
    if (BranchId) params['BranchId'] = BranchId;
    if (SearchBy) params['SearchBy'] = SearchBy;
    if (SearchByText) params['SearchByText'] = SearchByText;
    if (SpotId) params['SpotId'] = SpotId;

    if (From) params['From'] =  new Date(From).toISOString();
    if (To) params['To'] = new Date(To).toISOString();
    if(ResultOrder !== undefined) params['ResultOrder'] = ResultOrder;

    const queryParams = new URLSearchParams(params as Record<string, string>).toString();    
    return this.httpClient.get(`${baseUrl}?${queryParams}`);
  }
  
  orderStatus(){
    return this.httpClient.get(`${environment.apiUrl}Lookup/order-statuses`)
  }

  getBranchList(){
    return this.httpClient.get(`${environment.apiUrl}User/GetUserBranches`)
  }

  getCarBrandsList(){
    return this.httpClient.get(`${environment.apiUrl}Lookup/car-brands`)
  }

  orderDetails({ orderId }: { orderId: string }){
    return this.httpClient.get(`${environment.apiUrl}Order/Details/${orderId}`)
  }

  changeStatus({orderId, statusId, branchId}: IQuery){
    const data = {orderId, statusId, branchId}
    return this.httpClient.put(`${environment.apiUrl}Order/status`, data)
  }

  UpdateDeliveredAndNotPaidOrderStatusDto({orderId, statusId, branchId,note}: IQuery){
    const data = {orderId, statusId, branchId,note}
    return this.httpClient.put(`${environment.apiUrl}Order/UpdateDeliveredAndNotPaidOrderStatusDto`, data)
  }

  getBranchPercentage(branchId:number){
    return this.httpClient.get(`${environment.apiUrl}Branch/GetBranchPercentage?id=${branchId}`)
  }

  createOrder(orderData:any){
    return this.httpClient.post(`${environment.apiUrl}Order/create`,orderData)
  }
  updateOrder(data:any, id:number) {
    let url = `${environment.apiUrl}Order/${id}`;
    return this.httpClient.put(url, data);
  }

  getOrderStatus(id:number){
    return this.httpClient.get(`${environment.apiUrl}Order/GetOrderStatuses?id=${id}`);
  }
  getOrderConfigurations(){
    return this.httpClient.get(`${environment.apiUrl}Order/configurations`);
  }
  
  GetPrintableOrder(orderId: string, branchId: string){
    return this.httpClient.get(`${environment.apiUrl}Order/print-customer?id=${orderId}&branchId=${branchId}`);
  }
  GetBranchParkingFloorsAndFloorSpots( branchId: number,isEditMode:boolean,spotId:any){
    return this.httpClient.get(`${environment.apiUrl}Order/GetBranchParkingFloorsAndFloorSpots?branchId=${branchId}&isEdit=${isEditMode}&spotId=${spotId}`);
  }
  GetOccupiedBranchParkingSpots( branchId: number){
    return this.httpClient.get(`${environment.apiUrl}Order/GetOccupiedBranchParkingSpots?branchId=${branchId}`);
  }
  UpdateCustomerMobileNumber( orderId: string,customerMobileNumber:string){
    return this.httpClient.get(`${environment.apiUrl}Order/UpdateCustomerMobileNumber?orderId=${orderId}&customerMobileNumber=${customerMobileNumber}`);
  }
  DailyBranchReport( branchId: number,dateFrom:Date,dateTo:Date){
    return this.httpClient.get(`${environment.apiUrl}Order/DailyBranchReport?branchId=${branchId}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
  }
  PrintDailyBranchReport( token :string, branchId: number,dateFrom:Date,dateTo:Date){
    return this.httpClient.get(`${environment.apiUrl}Order/PrintDailyBranchReport?token=${token}&branchId=${branchId}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
  }
  CalculateTiersPrice( orderId: string){
    return this.httpClient.get(`${environment.apiUrl}Order/CalculateTiersPrice?orderId=${orderId}`);
  }
}
