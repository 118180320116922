import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuardService {
  isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private authServices: AuthService
  ) { }

  canActivate() {
    this.isLoggedIn = localStorage.getItem('token') ? true : false;
    if (this.isLoggedIn) {
      if(this.authServices.isBranchUser()){
        this.router.navigate(['/orders'],{ state: { newLogin: true } });
      }else{
        this.router.navigate(['/dashboard']);
      }
      this.getCurrentUSerInfo();
      return false;
    }
    return true;
  }

  getCurrentUSerInfo(){
    this.authServices.getUserInfo().subscribe(
      (res:any) =>{
        console.log(res)
        localStorage.setItem('UserInfo', JSON.stringify(res));
      },
      (err) => {
      }
    )
  }
}
