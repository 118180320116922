import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  isLoggedIn: boolean = false;

  constructor(private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const roles = route.data['roles'] as Array<string>;
    this.isLoggedIn = localStorage.getItem('token') ? true : false;
    if (this.isLoggedIn) {
      for (let i = 0; i < roles.length; i++) {
        if (this.auth.getRole() == roles[i]) {
          return true
        }
      }
      window.location.href = '/auth/login';
      return false;
    }
    window.location.href = '/auth/login';
    return false;
  }

}
