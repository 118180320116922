import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, finalize, Observable, switchMap, throwError, from } from "rxjs";
import { LoaderService } from "src/app/services/loader.service";
import { AuthService } from "src/app/services/auth.service"; // Assuming AuthService is the service handling authentication

@Injectable({ providedIn: "root" })
export class GlobalHttpInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private authService: AuthService // Injecting AuthService to handle refresh token logic
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Show loader for all requests except the ones that include specific URLs
    if (
      !req.url.includes("GetUnSeenCount") &&
      !req.url.includes("GetOrderStatuses") &&
      !req.url.includes("&statusId=3")
      &&
      !req.url.includes("User/refresh")
    ) {
      this.loaderService.show(); // Show loader
    }

    // Retrieve token and language from localStorage
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('lang') ?? "en";

    // Clone the request to add the new headers
    let updatedRequest = req.clone({
      setHeaders: {
        "accept-language": language,
        'Authorization': token ? `Bearer ${token}` : ''
      },
    });
    return next.handle(updatedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
      
        if (error.status === 401 || error.status === 0) {
          // if (req.url.includes("User/refresh")
          // ) {
          //   this.authService.logout();
          // }
          // localStorage.removeItem('token')
          if (!req.url.includes("googleapis") && !req.url.includes("GetUnSeenCount") ) {
            return from(this.authService.refreshToken()).pipe(
              switchMap(() => {
                // Retry the failed request with the new token
                const newToken = localStorage.getItem('token');
                updatedRequest = updatedRequest.clone({
                  setHeaders: {
                    "accept-language": language,
                    'Authorization': newToken ? `Bearer ${newToken}` : ''
                  }
                });
                return next.handle(updatedRequest);
              }),
              catchError(() => {
                // Handle failure to refresh the token
                this.handleUnauthorized();
                return throwError(() => error);
              })
            );
          }
          
        }
        // Handle other errors and hide loader
        this.loaderService.hide();
        return throwError(() => error);
      }),
      finalize(() => {
        // Ensure the loader is hidden after the request completes
        this.loaderService.hide();
      })
    );
  }

  private handleUnauthorized() {
    // Clear cookies and cache
    this.clearCookies();
    this.clearLocalStorage();
    this.clearSessionStorage();

    // Redirect to login page
    window.location.href = '/auth/login';
  }

  private clearCookies() {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }
  }

  private clearLocalStorage() {
    localStorage.clear();
  }

  private clearSessionStorage() {
    sessionStorage.clear();
  }
}
