import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import * as signalR from '@microsoft/signalr';
import { ToastrService } from "ngx-toastr";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Subscription, switchMap, timer } from "rxjs";
import { NotificationsServices } from "./services/notifications.service";
import { Router } from "@angular/router";
import { LoaderService } from "./services/loader.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isCollapsed = false;
  isAuthenticated = false;
  hubConnection:any;
  subscription !: Subscription;
  unSeenNotificationCount:any;
  currentLang = localStorage.getItem('lang') || "ar";
  loading$ = this.loaderService.loading$; // Bind the loader state to a property

  constructor(
    public authService: AuthService,
    private translateService: TranslateService,
    private toastr:ToastrService,
    private router :Router,
    private notification: NzNotificationService,
    private notificationsServices : NotificationsServices,
    private loaderService: LoaderService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.changeLangage(this.currentLang);
   }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
    // this.initNotificationConnection();
    // if (this.isAuthenticated && this.authService.isBranchUser()) {
    //   // this.initNotificationConnection();
    //   this.subscription = timer(0, 5000).pipe(
    //     switchMap(() => this.notificationsServices.unSeenCount())
    //   ).subscribe((result :any) => 
    //     {
    //       if(localStorage.getItem('currentBranch')){
    //         this.unSeenNotificationCount = result
    //       }
    //       this.authService.checkTokenAndRedirect()
    //     }
    //   );
    // }
    
  }

  openNotification(){
    this.router.navigate(['notifications/list']);

  }


  // initNotificationConnection(){
  //   this.hubConnection = new signalR.HubConnectionBuilder()
  //   .withUrl('https://admin.smartvalet.inzox.co/notification-hub/', {
  //     skipNegotiation: true,
  //     transport: signalR.HttpTransportType.WebSockets
  //   })
  //   .build();

  //   this.hubConnection.start().then( () => {
  //     console.log("SignalR connected");
  //     if(localStorage.getItem('currentBranch')){
  //       this.subscribeToBranch(Number(localStorage.getItem('currentBranch')));
  //     }
  //   }).catch((err:any) => console.error(err));

  //   this.hubConnection.on('ReceiveMessage', (message: string) => {
  //     console.log("message" , message)
  //     this.notification
  //     .blank(
  //       message,
  //       '',
  //       { nzPlacement: 'bottomRight' }
  //     )
  //   }); 
  // }

  subscribeToBranch(branchName: number){
    this.hubConnection.invoke('SubscribeToBranch', branchName).catch((err:any) => console.error(err));
  };

  changeLangage(lang: string) {
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    localStorage.removeItem('lang');
    localStorage.setItem('lang', lang);
    htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.changeCssFile(lang);
    const body = document.querySelector("body")!;
    body.className = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  }

  changeCssFile(lang: string) {
      let headTag = this.document.getElementsByTagName("head")[0] as HTMLHeadElement;
      let existingLink = this.document.getElementById("langCss") as HTMLLinkElement;
      let bundleName = lang === "ar" ? "arabicStyle.css":"englishStyle.css";
      if (existingLink) {
        existingLink.href = bundleName;
      } else {
        let newLink = this.document.createElement("link");
        newLink.rel = "stylesheet";
        newLink.type = "text/css";
        newLink.id = "langCss";
        newLink.href = bundleName;
        headTag.appendChild(newLink);
      }
    }
}
