<div class="header" nz-row nzJustify="space-between" nzAlign="middle">
  <div  nz-col class="flex-start logo-widget">
    <div nz-col class="header-logo">
      <img
        height="15px"
        src="/assets/images/logo/ur-valet logo transparent.png"
        alt="UR Valet Logo"
      />
  
  </div>

    <nz-select  [(ngModel)]="selectedBranch"
    style="padding-left: 90px;"
    (ngModelChange)="changeBranch($event)" nzPlaceHolder="{{'select_branch' | translate}}" nzBorderless *ngIf="authService.isBranchUser() && selectedBranch">
      <nz-option style="color: #bfbfbf;"
        *ngFor="let branch of branchesList"
        [nzValue]="branch.Id"
        [nzLabel]="branch.Name || '-'"
      ></nz-option>
    </nz-select>

  </div>
  <!-- <div nz-col nzXl="12" class="flex-start">
    <nz-input-group class="search" [nzPrefix]="prefixTemplateSearch">
      <input type="text" nz-input />
    </nz-input-group>
    <ng-template #prefixTemplateSearch>
      <span nz-icon nzType="search"></span>
    </ng-template>
    <span nz-icon nzType="filter" class="filters"></span>
  </div> -->
  <div nz-col class="flex-end nav-actions">
    <div class="branch-widget" *ngIf="authService.isBranchUser()">
      <a (click)="logout()"><img src="../../../assets/svg/menu/vuesax-bold-logout.svg" alt=""></a>
      <a (click)="goToOrders()"><img src="../../../assets/svg/menu/vuesax-bold-note.svg" alt=""></a>
    </div>
    <button nz-button class="lang-switcher" nzType="default" [nzSize]="'large'" nzShape="circle" (click)="changeLangage(currentLang == 'en' ? 'ar' : 'en')">{{currentLang == 'en' ? 'ع' : 'E'}}</button>
    <!-- <div class="notification"  *ngIf="authService.isBranchUser()" (click)="openNotifications()">
      <span *ngIf="unSeenNotificationCount > 0" class="notification-count">{{unSeenNotificationCount}}</span>
      <span nz-icon nzType="bell" nzTheme="fill"></span>
    </div> -->
    <div class="profile" (click)="openProfile()">
      <nz-avatar [nzSize]="'large'" nzText="{{initials ? initials : 'N/A' }}" style="background-color: #2B50AA"></nz-avatar>

      <span>{{userInfo?.fullName ? userInfo?.fullName : "N/A"}}</span>
      <span nz-icon nzType="caret-down" nzTheme="outline"></span>
    </div>
  </div>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="{{'my_profile' | translate}}" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
  nzClassName="profile-modal" *ngIf="userInfo">
  <ng-container *nzModalContent>
    <!-- <img src="https://placehold.co/600x400" alt="" /> -->
    <h3 class="name">{{userInfo?.fullName ? userInfo?.fullName : "N/A"}}</h3>
    <p>{{userInfo?.email ? userInfo?.email : "N/A"}}</p>
    <p>{{userInfo?.role ? userInfo?.role : "N/A"}}</p>
  </ng-container>
  <div *nzModalFooter class="custom-footer">
    <button nz-button nzType="primary" (click)="openChangePassword()">
      {{'change_password' | translate}}
    </button>
    <!-- <button nz-button nzType="text">Show Company Branch Profile</button> -->
    <button nz-button nzType="text" (click)="logout()">{{'logout' | translate}}</button>

  </div>
</nz-modal>
<nz-modal [(nzVisible)]="changePassword" nzTitle="{{'change_password' | translate}}"
          (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" nzClassName="change-password-modal">
  <ng-container *nzModalContent>
    <div class="input-widget" nz-col [nzSpan]="24">
      <label> {{'old_password' | translate}}</label>
      <div class="password-widget">
        <input [(ngModel)]="oldPassword" [type]="passwordVisible ? 'text' : 'password'" nz-input
               placeholder="{{'old_password' | translate}}">
        <span nz-icon  [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
              (click)="passwordVisible = !passwordVisible"></span>
      </div>
    </div>
    <div class="input-widget" nz-col [nzSpan]="24">
      <label>{{'new_password' | translate}}</label>
      <div class="password-widget">
        <input [(ngModel)]="newPassword"  [type]="passwordVisible ? 'text' : 'password'"  nz-input
               placeholder="{{'new_password' | translate}}" />
        <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
        (click)="passwordVisible = !passwordVisible"></span>
      </div>
    </div>
    <div class="input-widget" nz-col [nzSpan]="24">
      <label>{{'confirm_new_password' | translate}}</label>
      <div class="password-widget">
        <input [(ngModel)]="confirmNewPassword" [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="{{'confirm_new_password' |translate}}" />
               <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
               (click)="passwordVisible = !passwordVisible"></span>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter class="custom-footer">
    <button nz-button nzType="primary" (click)="submitChangePassword()">{{'submit' | translate}}</button>
  </div>
</nz-modal>
