import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationsServices {

  constructor(
    private httpClient: HttpClient,
  ) { }

  allNotifications(BranchId :number , PageIndex:number) {
    return this.httpClient.get(`${environment.apiUrl}Notification/GetUserNotifications?BranchId=${BranchId}&PageIndex=${PageIndex}&PageSize=10`)
  }

  unSeenCount(){
    return this.httpClient.get(`${environment.apiUrl}Notification/GetUnSeenCount`)
  }
  SetSeen(id :number){
    return this.httpClient.put(`${environment.apiUrl}Notification/SetSeen?id=${id}`,null)
  }




}
