<nz-spin
  nzSimple
  [nzSize]="'large'"
  *ngIf="loading$ | async"
  class="loader-container"
>
  <!-- Optional: Add custom loading text or logo -->
  <div class="loading-text">
    <span>Loading...</span>
  </div>
</nz-spin>

<nz-layout *ngIf="isAuthenticated" [ngClass]="{ 'branch-user-class': authService.isBranchUser() }">
  <nz-header>
    <app-header [unSeenNotificationCount]="unSeenNotificationCount"></app-header>
  </nz-header>
  <nz-layout>
    <nz-sider [nzWidth]="140" nzCollapsible nzBreakpoint="md" [nzCollapsedWidth]="0">
      <div class="side-menu-container">
        <app-side-menu></app-side-menu>
      </div>
    </nz-sider>
    <nz-content>
      <div class="notification-alert"  *ngIf="unSeenNotificationCount > 0 && authService.isBranchUser()" (click)="openNotification()">
        <nz-alert nzType="warning"  [nzIcon]="customIconTemplate"
        nzShowIcon nzMessage=" {{unSeenNotificationCount}} {{'orders.notification_missed' | translate}}"></nz-alert>
        <ng-template #customIconTemplate>
          <span nz-icon nzType="notification" nzTheme="outline"></span>
        </ng-template>
      </div>
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>

<nz-layout *ngIf="!isAuthenticated">
  <router-outlet></router-outlet>
</nz-layout>