import { Component, OnInit,NgModule } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})

export class SideMenuComponent implements OnInit{
  isVisible = false;
  userInfo :any;
  initialsStr:any;
  initials:any;
  changePassword= false;
  isCompanyAdmin: boolean = false;
  isSuperAdmin: boolean = false;

  constructor(public authService:AuthService){
    this.isCompanyAdmin = this.authService.isCompanyAdmin();
    this.isSuperAdmin = this.authService.isSuperAdmin();

  }

  ngOnInit(): void {
    this.getProfileData();
  }

  openProfile(){
    this.isVisible = true;
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("UserInfo");
    localStorage.removeItem("currentBranch");
    location.reload();
  }

  handleCancel(){
    this.isVisible = false;
    this.changePassword = false;
  }

  openChangePassword(){
    this.changePassword = true;
    this.isVisible = false;
  }
  
  handleOk(){
    this.isVisible = false;
    this.changePassword = false;

  }

  getProfileData(){
    setTimeout(() => {
      this.userInfo = JSON.parse(localStorage.getItem("UserInfo") || '{}');
      this.getinitials();
    }, 500);
  }

  getinitials() {
    const tokens = this.userInfo?.fullName?.split(' ')?.slice(0, 2);
    this.initialsStr = tokens?.join(' ');
    this.initials = this.initialsStr
      .split(' ')
      .map(function (str:any) {
        return str ? str[0].toUpperCase() : '';
      })
      .join('');
  }


}
