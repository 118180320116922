import { environment } from 'src/environments/environment.development';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private router: Router,
    private httpClient: HttpClient,
  ) { }

  login(userData: IUserData) {
    return this.httpClient.post(`${environment.apiUrl}User/login`, userData)
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      // this.logout();
      return Promise.reject('No refresh token available');
    }
    return this.httpClient.post(`${environment.apiUrl}User/refresh/${refreshToken}`, null)
      .toPromise()
      .then((response: any) => {
        if (response?.access_token) {
          localStorage.setItem('token', response.access_token);
        }
      })
      .catch(() => {
      });
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/auth/login';
  }

  // async checkTokenAndRedirect(): Promise<void> {
  //   const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

  //   if (token) {
  //     if (this.jwtHelper.isTokenExpired(token)) {
  //       try {
  //         await this.refreshToken();
  //       } catch {
  //         this.logout();
  //       }
  //     }
  //   } else {
  //     this.logout();
  //   }
  // }
  getUserInfo() {
    return this.httpClient.get(`${environment.apiUrl}User`,)
  }

  updateUserCurrentBranch(id: number) {
    return this.httpClient.post(`${environment.apiUrl}User/UpdateUserCurrentBranch/`, id)
  }

  forgotPassword(UserData: IUserData) {
    return this.httpClient.post(`${environment.apiUrl}User/forgot-password`, UserData)
  }

  setPassword(userData: IUserData) {
    return this.httpClient.post(`${environment.apiUrl}User/set-password`, userData)
  }
  setPasswordViaInvitation(userData: IUserData) {
    return this.httpClient.post(`${environment.apiUrl}User/set-password-via-invitation`, userData)
  }

  changePassword(userData: any) {
    return this.httpClient.post(`${environment.apiUrl}User/change-password`, userData)
  }
  public isAuthenticated(): boolean {
    return localStorage.getItem('token') ? true : false;
  }

  getRole() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false
    }
    const { userRole } = this.jwtHelper.decodeToken(token)
    if (!userRole) {
      return false
    }
    return userRole;
  }
  isBranchUser() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false
    }
    const { userRole } = this.jwtHelper.decodeToken(token)
    if (!userRole || userRole !== 'BranchUser') {
      return false
    }
    // if (this.jwtHelper.isTokenExpired(token)) {
    //   return false
    // }
    return true;
  }

  isSuperAdmin() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false
    }
    const { userRole } = this.jwtHelper.decodeToken(token)
    if (!userRole || userRole !== 'SuperAdmin') {
      return false
    }
    if (this.jwtHelper.isTokenExpired(token)) {
      return false
    }
    return true;
  }

  isCompanyAdmin() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false
    }
    const { userRole } = this.jwtHelper.decodeToken(token)
    if (!userRole || userRole !== 'CompanyAdmin') {
      return false
    }
    // if (this.jwtHelper.isTokenExpired(token)) {
    //   return false
    // }
    return true;
  }

}

interface IUserData {
  username?: string,
  password?: string,
  email?: string,
  invitationToken?: string
}