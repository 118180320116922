import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnonymousGuardService } from './services/anonymous-guard.service';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: 'auth'
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AnonymousGuardService]
  },
  {
    path: 'bring-car',
    loadChildren: () => import('./pages/bring-car/bring-car.module').then(m => m.BringCarModule),
  },
  {
    path: 'bring-car-parking',
    loadChildren: () => import('./pages/bring-car-parking/bring-car-parking.module').then(m => m.BringCarParkingModule),
  },
  {
    path: 'self-checkin',
    loadChildren: () => import('./pages/self-checkin/self-checkin.module').then(m => m.SelfCheckinModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AuthGuardService],
    data: { roles: ['SuperAdmin', 'CompanyAdmin', 'BranchUser'] }
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuardService],
    data: { roles: ['SuperAdmin', 'CompanyAdmin'] }
  },
  {
    path: 'companies',
    loadChildren: () => import('./pages/companies/companies.module').then(m => m.CompaniesModule),
    canActivate: [AuthGuardService],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule),
    canActivate: [AuthGuardService],
    data: { roles: ['SuperAdmin', 'CompanyAdmin', 'BranchUser'] }
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuardService],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuardService],
    data: { roles: ['SuperAdmin', 'CompanyAdmin', 'BranchUser'] }
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuardService],
    data: { roles: ['SuperAdmin', 'CompanyAdmin', 'BranchUser'] }
  },
  {
    path: 'branches',
    loadChildren: () => import('./pages/branches/branches.module').then(m => m.BranchesModule),
    canActivate: [AuthGuardService],
    data: { roles: ['SuperAdmin', 'CompanyAdmin', 'BranchUser'] }
  },
  {
    path: 'user-branches',
    loadChildren: () => import('./pages/user-branches/user-branches.module').then(m => m.UserBranchesModule),
    canActivate: [AuthGuardService],
    data: { roles: ['SuperAdmin', 'CompanyAdmin', 'BranchUser'] }
  },




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
