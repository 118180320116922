<div class="side-menu">
    <div class="profile" (click)="openProfile()">
        <nz-avatar [nzSize]="'large'" nzText="{{initials ? initials : 'N/A' }}" style="background-color: #2B50AA"></nz-avatar>

        <div>
            <span>{{userInfo?.fullName ? userInfo?.fullName : "N/A"}}</span>
        </div>
    </div>

    <a [routerLink]="['/reports']" routerLinkActive="router-link-active" *ngIf="isSuperAdmin || isCompanyAdmin">
        <img width="29px" height="29px" src="/assets/svg/menu/vuesax-bold-home-2.svg" />
        <span>{{ "reports" | translate }}</span>
    </a>
    <a [routerLink]="['/orders']" routerLinkActive="router-link-active">
        <img width="29px" height="29px" src="/assets/svg/menu/vuesax-bold-note.svg" />
        <span>{{ "orders.title" | translate }}</span>
    </a>
    <a *ngIf="isSuperAdmin" [routerLink]="['/orders/list-new']" routerLinkActive="router-link-active">
        <img width="29px" height="29px" src="/assets/svg/menu/vuesax-bold-note.svg" />
        <span>{{ "orders_new.title" | translate }}</span>
    </a>
    <a *ngIf="isSuperAdmin" [routerLink]="['/companies']" routerLinkActive="router-link-active">
        <img width="29px" height="29px" src="/assets/svg/menu/vuesax-bold-building-4.svg" />
        <span>{{ 'companies' | translate }}</span>
    </a>
    <a *ngIf="isSuperAdmin || isCompanyAdmin" [routerLink]="['/branches']" routerLinkActive="router-link-active">
        <img width="29px" height="29px" src="/assets/svg/menu/vuesax-bold-buildings-2.svg" />
        <span>{{ 'branches' | translate }}</span>
    </a>
    <a *ngIf="isSuperAdmin || isCompanyAdmin" [routerLink]="['/user-branches']" routerLinkActive="router-link-active">
        <img width="29px" height="29px" src="/assets/svg/menu/vuesax-bold-profile-2user.svg" />
        <span>{{ 'user_branches' | translate }}</span>
    </a>
    <!-- <a  *ngIf="!authService.isCompanyAdmin() && !authService.isBranchUser()" [routerLink]="['/customers']" routerLinkActive="router-link-active">
        <img width="29px" height="29px" src="/assets/svg/menu/vuesax-bold-people.svg" />
        <span>{{ "customers.title" | translate }}</span>
    </a> -->
    <!-- <a *ngIf="!authService.isCompanyAdmin() && !authService.isBranchUser()" [routerLink]="['/settings']" routerLinkActive="router-link-active">
        <img width="29px" height="29px" src="/assets/svg/menu/vuesax-bold-setting-2.svg" />
        <span>{{ 'settings' | translate }}</span>
    </a> -->
    <a *ngIf="isSuperAdmin" [routerLink]="['/settings/terms-and-conditions']" routerLinkActive="router-link-active">
        <img width="29px" height="29px" src="/assets/svg/menu/terms-and-conditions.png" />
        <span>{{ 'terms_and_conditions' | translate }}</span>
    </a>
    <a (click)="logout()">
        <img width="29px" height="29px" src="/assets/svg/menu/vuesax-bold-logout.svg" />
        <span>{{ 'logout' | translate }}</span>
    </a>
   
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="MY PROFILE" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
  nzClassName="profile-modal" *ngIf="userInfo">
  <ng-container *nzModalContent>
    <!-- <img src="https://placehold.co/600x400" alt="" /> -->
    <h3 class="name">{{userInfo?.fullName ? userInfo?.fullName : "N/A"}}</h3>
    <p>{{userInfo?.email ? userInfo?.email : "N/A"}}</p>
    <p>{{userInfo?.role ? userInfo?.role : "N/A"}}</p>
  </ng-container>
  <div *nzModalFooter class="custom-footer">
    <!-- <button nz-button nzType="text">Show Company Branch Profile</button> -->
    <button nz-button nzType="text" (click)="logout()">Logout</button>

  </div>
</nz-modal>

