import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, switchMap, timer } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationsServices } from 'src/app/services/notifications.service';
import { OrdersService } from 'src/app/services/orders.service';
import { DOCUMENT } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isVisible = false;
  changePassword = false;
  passwordVisible = true;
  userInfo: any;
  initialsStr: any;
  initials: any;
  branchesList: any;
  selectedBranch: any;
  subscription !: Subscription;
  @Input() unSeenNotificationCount?: any;
  currentLang = localStorage.getItem('lang') || "en";
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  isBranchUser: boolean = false;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private orderService: OrdersService,
    public authService: AuthService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,

  ) {
    this.isBranchUser = this.authService.isBranchUser();
  }
  ngOnInit(): void {
    this.getProfileData();
    this.listBranches();

  }

  changeLangage(lang: string) {
    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
  
    // Store selected language in localStorage
    localStorage.setItem('lang', lang);
    // Set the direction of the page
    htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
  
    // Set the current language for translation service
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang).subscribe(() => {
      // After the language has changed, apply the corresponding CSS
      this.changeCssFile(lang);
  
      // Update body class to reflect the language direction (rtl or ltr)
      const body = document.querySelector("body")!;
      body.className = lang === "ar" ? "rtl" : "ltr";
      
      // Update currentLang variable for future use
      this.currentLang = lang;
      if (!this.isBranchUser) {
        window.location.reload()
      }

    }, (error) => {
      console.error('Language change error:', error);
    });
  }
  

  goToOrders(){
    this.router.navigate([`orders/list/`]);
  }
  
  changeCssFile(lang: string) {
    const headTag = this.document.getElementsByTagName("head")[0] as HTMLHeadElement;
    let existingLink = this.document.getElementById("langCss") as HTMLLinkElement;
    
    // Set the appropriate CSS file for the selected language
    const bundleName = lang === "ar" ? "arabicStyle.css" : "englishStyle.css";
    
    if (existingLink) {
      // If the link already exists, just update the href
      existingLink.href = bundleName;
    } else {
      // Create a new link element if it doesn't exist
      const newLink = this.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.type = "text/css";
      newLink.id = "langCss";
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }
  




  changeBranch(event: any) {
    this.authService.updateUserCurrentBranch(event).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.Data.access_token);
        localStorage.setItem('refresh_token', res.Data.refresh_token);
        localStorage.setItem('currentBranch', event);
        this.toastr.success(this.translateService.instant('branch_changed_successfly'));
        localStorage.removeItem('BranchServicePrice');
        if (this.branchesList.length >0) {
          const branch = this.branchesList.find(
            (b: any) => b.Id === this.selectedBranch
          );
          if (branch) {
            localStorage.setItem('BranchServicePrice', branch.BranchServicePrice);
  
          }
        }
        location.reload();
      },
      (err: any) => {
        this.toastr.error(err.error.Message);
      }
    )
  }

  getinitials() {
    const tokens = this.userInfo?.fullName?.split(' ')?.slice(0, 2);
    this.initialsStr = tokens?.join(' ');
    this.initials = this.initialsStr
      .split(' ')
      .map(function (str: any) {
        return str ? str[0].toUpperCase() : '';
      })
      .join('');
  }

  listBranches() {
    this.orderService.getBranchList().subscribe((res: any) => {
      this.branchesList = res.Data.branchDetailsList;
      if (localStorage.getItem('currentBranch') != null) {
        this.selectedBranch = Number(localStorage.getItem('currentBranch'));
      }


    })
  }

  getProfileData() {
    setTimeout(() => {
      this.userInfo = JSON.parse(localStorage.getItem("UserInfo") || '{}');
      this.getinitials();
    }, 500);
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("UserInfo");
    localStorage.removeItem("currentBranch");
    location.reload();
  }

  openProfile() {
    this.isVisible = true;
  }

  handleCancel() {
    this.isVisible = false;
    this.changePassword = false;
  }

  handleOk() {
    this.isVisible = false;
    this.changePassword = false;

  }
  openChangePassword() {
    this.changePassword = true;
    this.isVisible = false;
  }
  submitChangePassword() {
    if (this.newPassword !== this.confirmNewPassword) {
      this.toastr.error(this.translateService.instant('new_password_and_confirm_not_match'));
      return;
    }
    // Create the request payload
    const payload = {
      currentPassword: this.oldPassword,
      newPassword: this.newPassword,
      confirmNewPassword: this.confirmNewPassword
    };
    this.authService.changePassword(payload).subscribe((res: any) => {
      if (res?.Ok) {
        this.toastr.success(res?.Message);
        this.resetChangePasswordForm();
        this.handleCancel();
      }
    },
    (error) => {
      this.toastr.error(error?.error?.Message);
      
    })
  }
  resetChangePasswordForm(): void {
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmNewPassword = '';
  }
  // openNotifications() {
  //   this.router.navigate(['notifications/list']);
  // }

}
